h1 {
    text-align: center;
}

.about {
    padding: 0 45px;
    max-width: 100%;
}

.description {
    text-align: justify;
}

ul.facilities {
    list-style-type:none;
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
}
.fac {
    padding: 25px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    align-items: center;
    background-color: rgba(255,255,255,0.67);
    border-radius: 4px;
    box-shadow: 0px 2px 4px var(--menuitem-color);

}


.list-item-text {
    margin-left: 10px;
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;

}

.closest-beaches {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
}

.beach {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    padding: 10px 35px;
    border-radius: 15px;
    margin: 20px;
    background-color: var(--itemactive-color);
    box-shadow: 0px 2px 4px var(--menuitem-color);
}
.beach-info {
    margin-left: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--text-font-size);
}

.other-fac {
    padding: 30px 10px;
    display:flex;
    flex-wrap: wrap;
    align-items:flex-start;
    justify-content: center;
}

.heading {
    font-weight: bold;
    text-transform: uppercase;
}

.addCharge {
    padding: 5px 10px;
    margin: 5px  5px;
    border-radius: 10px;
    background-color: #94a3b3;
    color: white;
    font-size: 9px;
}

.facility {
    padding: 6.5px 15px;
    margin: 15px 15px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--itemactive-color);
    border-radius: 15px;
    max-width: 350px;
    flex: 2 1 auto;
    box-shadow: 0px 2px 4px var(--menuitem-color);

}

.fac-heading {
    font-size: 1rem;
    font-weight: bold;
}


.fac-desc {
    text-align: center;
}

.fac-list-item-heading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fac-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.fac-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.fac-item-text {
    margin: 0;
    padding: 5px 10px;
    font-size: 1em;
}

.fac-desc-mini {
    font-size: 1em;
}

@media screen and (max-width: 1300px) {
    .beach-info {        
        font-size: var(--text-font-size1300px);
    }
    .fac-heading {
        font-size: var(--text-font-size1300px);
    }
}

@media screen and (max-width: 1000px) {
    .beach-info {        
        font-size: var(--text-font-size1000px);
    }

    .fac-heading {
        font-size: var(--text-font-size1000px);
    }
}

@media only screen and (max-width: 700px) {

    .fac {
        padding: 30px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content:center;
        align-items: flex-start;
        border-radius: 4px;
    }

    ul.facilities {
        list-style-type:none;
        display: flex;
        justify-content:flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .padding-modal {
        padding: 1rem 2rem;
    }

    .beach-info {
        font-size: 1rem;
    }

    .fac-heading {
        font-size: var(--text-font-size700px);
    }
}

  
  @media screen and (max-width: 400px) {
    .beach-info {
        font-size: var(--text-font-size400px)
  }
  .fac-heading {
      font-size: var(--text-font-size400px);
  }
}